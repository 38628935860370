import { AxiosResponse } from "axios";
import CoreClient from './core.client';

export interface AuthApi {
  login(payload: Requests.Login): Promise<AxiosResponse<Responses.Login>>;
  register(payload: Requests.Register): Promise<AxiosResponse<Responses.Register>>;
  refreshToken(payload: Requests.RefreshToken): Promise<AxiosResponse<Responses.RefreshToken>>;
  logout(): Promise<void>;
  getMe(): Promise<AxiosResponse<Responses.GetMe>>;
}

export declare namespace Responses {
  interface Register {

  }

  interface Login {
    authToken: string;
    refreshToken: string;
  }

  interface GetMe {
    username: string;
    email: string;
    photo: string;
    accountType: number;
  }

  interface RefreshToken {
    authToken: string;
    refreshToken: string;
  }
}

export declare namespace Requests {
  interface Register {
    username: string;
    email: string;
    plaintextPassword: string;
  }

  interface Login {
    usernameOrEmail: string;
    plaintextPassword: string;
  }

  interface RefreshToken {
    refreshToken: string;
  }
}

class AuthClient implements AuthApi {
  async login(payload: Requests.Login): Promise<AxiosResponse<Responses.Login>> {
    return CoreClient.client.post<Responses.Login>("/investors/login", payload);
  }

  async register(payload: Requests.Register): Promise<AxiosResponse<Responses.Register>> {
    return CoreClient.client.post<Responses.Register>("/investors/register", payload);
  }

  async refreshToken(payload: Requests.RefreshToken): Promise<AxiosResponse<Responses.RefreshToken>> {
    return CoreClient.client.post<Responses.RefreshToken>("/investors/refresh-token", payload);
  }

  async logout(): Promise<void> {
    return CoreClient.client.post("/investors/logout");
  }

  async getMe(): Promise<AxiosResponse<Responses.GetMe>> {
    return CoreClient.client.get("/investors/me");
  }
}

export default new AuthClient();