<template>
  <v-container>
    <v-row>
      <h3 class="mb-1"><v-icon left class="mb-1">mdi-bank</v-icon>Analyst Ratings</h3>
    </v-row>
    <v-row>
      <v-col>
        <v-row v-if="aggregateAnalystRatings" class="analyst-ratings-container" >
          <div class="analyst-rating">

            <p v-if="analystBuyPct > 0" class="happy--text analyst-text">{{ analystBuyPct }}% BUY</p>
            <p v-else class="analyst-text">{{ analystBuyPct }}% BUY</p>
            <v-progress-linear class="mb-1 pb-1" :value="analystBuyPct" :color="analystBuyPct > 0 ? 'happy' : 'alternate'"></v-progress-linear>
          </div>

          <div class="analyst-rating">
            <p v-if="analystSellPct > 0" class="sad--text analyst-text">{{ analystSellPct }}% SELL</p>
            <p v-else class="analyst-text">{{ analystSellPct }}% SELL</p>
            <v-progress-linear class="mb-1 pb-1" :value="analystSellPct" :color="analystSellPct > 0 ? 'sad' : 'alternate'"></v-progress-linear>
          </div>

          <div class="analyst-rating">
            <p class="alternate--text analyst-text">{{ analystHoldPct }}% HOLD</p>
            <v-progress-linear class="mb-1 pb-1" :value="analystHoldPct" color="alternate"></v-progress-linear>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <h3><v-icon left class="mb-1">mdi-target</v-icon>Recent Price Targets</h3>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-card class="price-target-card" elevation="2" tile v-for="(target, i) in topThreePriceTargets" :key="i">
          <v-card-title style="padding:16px 16px 0">
            <p v-if="target.simplifiedRating === 'buy'" class="happy--text price-target-text">{{ target.simplifiedRating.toLocaleUpperCase() }}</p>
            <p v-else-if="target.simplifiedRating === 'sell'" class="sad--text price-target-text">{{ target.simplifiedRating.toLocaleUpperCase() }}</p>
            <p v-else class="alternate--text price-target-text">{{ target.simplifiedRating.toLocaleUpperCase() }}</p>
            <v-spacer/>
            <p>{{ target.analyst }}</p>
          </v-card-title>

          <v-card-subtitle>
            <p style="font-size: small; margin-bottom: 0">Target Price: {{ target.priceTarget ? currencyFormatter.format(target.priceTarget) : '--' }}</p>
            <p style="font-size: small; margin-bottom: 0; display: inline">Previous Target: {{ target.previousPriceTarget ? currencyFormatter.format(target.previousPriceTarget) : '--' }}</p>
            <p style="font-size: x-small" class="float-right">{{ target.date }}</p>
          </v-card-subtitle>
        </v-card>
        <a
          @click="viewAllPriceTargets"
          class="alternate--text float-end"
          style="font-size: small"
        >View all price targets</a>
      </v-col>

    </v-row>
<!--    <p v-for="(value, metric) in analystRatings.fundamentalDataModel" :key="metric+value" class="small-font mb-0">{{metric}}: {{value}}</p>-->
  </v-container>
</template>

<script>
  export default {
    props: {
      analystRatings: {required: true}
    },
    data() {
      return {
        currencyFormatter: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
    },

    methods: {
      viewAllPriceTargets() {
        this.showInfoSnackbar("Detailed price targets coming soon!")
      },

      simplifyRating(complexRating) {
        if (!complexRating) return;
        switch (complexRating.toLocaleLowerCase()) {
          case "buy":
          case "overweight":
          case "overperform":
            return "buy"

          case "sell":
          case "underweight":
          case "underperform":
            return "sell"

          case "hold":
          case "sector perform":
          case "peer perform":
          case "market perform":
          case "neutral":
          case "equal-weight":
            return "hold"
        }
      }
    },

    computed: {
      aggregateAnalystPriceTargets: function () {
        if (!this.analystRatings) return;
        const targets = []

        this.analystRatings.forEach(rating => {
          if (rating.pt_current) {
            targets.push({
              date: rating.date,
              analyst: rating.analyst,
              priceTarget: rating.pt_current,
              previousPriceTarget: rating.pt_prior,
              rawRating: rating.rating_current,
              simplifiedRating: this.simplifyRating(rating.rating_current),
              previousRating: rating.rating_prior,
            })
          }
        })
        return targets
      },

      aggregateAnalystRatings: function () {
        if (!this.aggregateAnalystPriceTargets) return;
        const ratings = [] // index: 0 -> buy, 1 -> sell, 2 -> hold, 3 -> sum of ratings

        const relevantPriceTargets = this.aggregateAnalystPriceTargets.filter(target => {
          const dateCutoff = new Date()
          dateCutoff.setMonth(dateCutoff.getMonth() - 12)
          return Date.parse(target.date) > (dateCutoff.getTime())
        });

        // Buy suggestions
        ratings[0] = relevantPriceTargets.filter(target => {
          return target.simplifiedRating === "buy"
        });

        // Sell suggestions
        ratings[1] = relevantPriceTargets.filter(target => {
          return target.simplifiedRating === "sell"
        });

        // Hold / neutral suggestions
        ratings[2] = relevantPriceTargets.filter(target => {
          return target.simplifiedRating === "hold"
        });

        // Total number of allocated ratings
        ratings[3] = ratings[0].length + ratings[1].length + ratings[2].length
        return ratings
      },

      analystBuyPct: function () {
        return Math.floor((this.aggregateAnalystRatings[0].length / this.aggregateAnalystRatings[3]) * 100);
      },

      analystSellPct: function () {
        return Math.floor((this.aggregateAnalystRatings[1].length / this.aggregateAnalystRatings[3]) * 100);
      },

      analystHoldPct: function () {
        return Math.floor((this.aggregateAnalystRatings[2].length / this.aggregateAnalystRatings[3]) * 100);
      },

      topThreePriceTargets: function() {
        if (!this.aggregateAnalystPriceTargets) return;
        return [...this.aggregateAnalystPriceTargets].sort((a, b) => b.date - a.date).slice(0, 3);
      }
    }
  }
</script>

<style scoped>
.analyst-ratings-container {
  display: flex;
  flex-flow: column;
}

.analyst-rating {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.analyst-text {
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 80px;
  font-weight: bold;
}

.price-target-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.price-target-text {
  font-style: italic;
  font-weight: bold;
}
</style>