import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import Home from '../pages/Home.vue'
import StockSearch from '../pages/StockSearch.vue';
import About from '../pages/About.vue';
import Instrument from '../pages/Instrument.vue';
import InvestorProfile from '../pages/InvestorProfile.vue'
import store from '@/store';
import routes from '@/router/routes';

const DEFAULT_ROUTE_UNAUTH = () => getRoutePathFromName(routes.HOME);

const requireAuth = async (to: Route, from: Route, next: Function) => {
  if (!store.getters.getAuthToken) return next(DEFAULT_ROUTE_UNAUTH());
  if (store.getters.getAuthToken && !store.getters.getIsLoggedIn) {
    // valid token but we're missing the user's profile, fetch it
    await store.dispatch("fetchMe")
    .then(() => {
      // proceed to authed route
      return next();
    })
    .catch(() => {
      // auth failure, send to unauthed route
      return next(DEFAULT_ROUTE_UNAUTH());
    });
  }
  // valid token and we already have the user's profile, proceed to authed route
  return next();
};

const fetchMeIfToken = async (to: Route, from: Route, next: Function) => {
  // We have an auth token in the store - the user has not logged in.
  if (!store.getters.getAuthToken) {
    return next();
  }

  // We have an auth token in local storage/the store, but no user in the store.
  // Wrapped in a try/catch because the token may exist, but it may not map to a valid user.
  try {
    if (store.getters.getAuthToken && !store.getters.getIsLoggedIn) {
      await store.dispatch("fetchMe")
    }
  } catch (e) {
    await store.dispatch("logout")
  }
  return next();
};

Vue.use(VueRouter);
const routeRecords: RouteConfig[] = [
  {
    path: '/',
    name: routes.HOME,
    component: Home,
    beforeEnter: fetchMeIfToken,
  },
  {
    path: '/search',
    name: routes.STOCK_SEARCH,
    component: StockSearch,
    beforeEnter: fetchMeIfToken,
  },
  {
    path: '/instruments/:symbol',
    name: routes.INSTRUMENT,
    component: Instrument,
    meta: { transitionName: 'slide' },
    beforeEnter: fetchMeIfToken,
  },
  {
    path: '/about',
    name: routes.ABOUT,
    component: About,
    beforeEnter: fetchMeIfToken,
  },
  {
    path: '/investors/me',
    name: routes.INVESTOR_PROFILE,
    component: InvestorProfile,
    beforeEnter: requireAuth,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routeRecords
});

const goToCustomRoute = (path: string) => {
  const firstChar = path.substr(0, 1);
  if (firstChar !== '/') {
    path = '/' + path;
  }

  if (router.currentRoute.fullPath !== path) {
    return router.push(path);
  }
}

function getRoutePathFromName(routeName: string) : string | null{
  const matchingRecords: RouteConfig[] = routeRecords.filter(route => route.name === routeName);
  if (matchingRecords.length != 1) return null;
  return matchingRecords[0].path;
}

const goToRoute = (routeName: string) => {
  const route: (string | null) = getRoutePathFromName(routeName);
  if (route == null) {
    throw new Error(`No official route for navigation request: '${routeName}'`);
  }

  if (router.currentRoute.fullPath !== route) {
    return router.push(route);
  }
}

export { goToRoute, goToCustomRoute, getRoutePathFromName, routes }

export default router
