<template>
  <v-main>
    <v-container>
      <v-row mt-5 class="text-center">
        <v-col class="mt-5">
          <h1 class="display-2 font-weight-bold mb-3" ref="coolTitle">
            find some new stocks.
          </h1>

          <v-row justify="center" class="mt-5">
            <v-col cols="12" sm="10" md="8">
              <v-text-field
                placeholder="Search 9,400+ US stocks and ETFs..."
                solo
                @keyup.enter.native=searchForStock()
                v-model="searchQuery"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12">
              <v-container v-if="searching" mb-5>
                <div class="text-center">
                  <v-progress-circular
                      indeterminate
                      color="primary"
                  ></v-progress-circular>
                </div>
              </v-container>

              <v-container v-else-if="searchResults === null">
                <p>Quote of the day goes here.</p>
              </v-container>

              <v-container v-else-if="!searchResults">
                <p>No results for that search query, try something different.</p>
              </v-container >

              <v-expansion-panels v-else class="custom-expansion-panels">
                <v-slide-y-transition group>
                  <StockPreview v-for="result in searchResults" :key="result.symbol" :stock="result"/>
                </v-slide-y-transition>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import StockPreview from "../components/instrument-preview/InstrumentPreview";
  import InstrumentService from "@/services/instrument.service";

  export default {
    components: {StockPreview},
    data() {
      return {
        searchQuery: "",
        searchResults: null,
        searching: false,
      };
    },
    methods: {
      async searchForStock() {
        this.searching = true;
        this.searchResults = await InstrumentService.search(this.searchQuery)
        this.searching = false;
      }
    },
    mounted() {
      if (this.$anime) {
        this.$anime.timeline().add({
          targets: this.$refs.coolTitle,
          opacity: [0,1],
          scale: [0.2, 1],
          duration: 800
        });
      }
    }
  }
</script>

<style scoped>
.v-expansion-panels.custom-expansion-panels {
  display: block;
}
</style>