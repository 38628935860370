<template>
  <v-main>
    <v-container>
      <v-row v-if="showHomeBanner" justify="center" class="mb-3">
        <v-col cols="12">
          <v-img v-if="$vuetify.theme.dark" src="../../public/floral.gif" height="200">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-skeleton-loader type="image" width="100%" height="200"/>
              </v-row>
            </template>
          </v-img>
          <v-img v-else src="../../public/floral-invert.gif" height="200">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-skeleton-loader type="image" width="100%" height="200"/>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="3">
          <span class="day-performance-headers">
            <h2 ref="portfolio" @click="changeDayPerformanceTab('portfolio')" :class="dayPerformanceTab === 'portfolio' ? 'day-performance-header selected' : 'day-performance-header'">portfolio</h2>
            <p class="styled-divider">//</p>
            <h2 ref="watchlist" @click="changeDayPerformanceTab('watchlist')" :class="dayPerformanceTab === 'watchlist' ? 'day-performance-header selected' : 'day-performance-header'">watchlist</h2>
          </span>
          <v-card elevation="2">
            <AssetDayPerformanceContainer v-show="dayPerformanceTab === 'portfolio'" :symbols="portfolioSymbols"/>
            <AssetDayPerformanceContainer v-show="dayPerformanceTab === 'watchlist'" :symbols="watchlistSymbols"/>
          </v-card>
        </v-col>

        <v-col cols="12" md="8" lg="9">
          <h2 ref="search" class="pa-3">search for stocks</h2>
          <StockSearchDropdown/>

          <h2 ref="socials" class="pa-3">what people are saying</h2>
          <v-card elevation="2">
            <TimelinePanel/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import store from "@/store"
  import AssetDayPerformanceContainer from "../components/instrument-mini-summary/AssetDayPerformanceContainer";
  import TimelinePanel from "../components/sentiment-timeline/TimelinePanel";
  import StockSearchDropdown from "@/components/search/StockSearchDropdown";
  import {EventBus, events} from "@/event-bus";

  export default {
    store,
    events,
    EventBus,
    components: {StockSearchDropdown, TimelinePanel, AssetDayPerformanceContainer},
    data() {
      return {
        portfolioSymbols: ['BABA', 'GPRO', 'HSY', 'MNMD', 'PLTR', 'RKLB'],
        watchlistSymbols: ['NVDA', 'AMD', 'GOOG', 'TSLA'],
        dayPerformanceTab: "portfolio"
      };
    },
    mounted() {
      if (this.$anime) {
        this.$anime.timeline().add({
          targets: [this.$refs.search, this.$refs.socials],
          translateY: [-30, 0],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        });

        this.$anime.timeline().add({
          targets: [this.$refs.watchlist],
          translateX: [-30, 0],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        });

        this.$anime.timeline().add({
          targets: [this.$refs.portfolio],
          translateX: [30, 0],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        });
      }
    },
    methods: {
      changeDayPerformanceTab(tabName) {
        this.dayPerformanceTab = tabName;
      }
    },
    computed: {
      showHomeBanner: function() {
        return store.getters.getShowHomeBanner;
      }
    },
  }
</script>

<style scoped>
  .day-performance-headers {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 16px;
  }

  .day-performance-header {
    cursor: pointer;
    color: gray;
  }

  .day-performance-header:hover {
    color: lightgray;
  }

  .selected {
    color: inherit;
  }

  .selected:hover {
    color: inherit;
  }

  .styled-divider {
    font-weight: normal;
    font-size: 18px;
    margin: 0 12px;
  }
</style>
