import {VuexModule, Mutation, Action, Module} from 'vuex-module-decorators'
import AuthService from "@/services/auth.service";
import InvestorService from "@/services/investor.service";
import {Investor, LoginFormData, RegisterFormData} from "@/common/types";
import {Responses} from "@/clients/auth.client";
import {isEmpty} from "@/utils";

@Module
export default class User extends VuexModule {

  // State.
  user: Investor = {} as Investor;
  authToken: string = localStorage.getItem("auth-token") || '';
  refreshToken: string = localStorage.getItem("refresh-token") || '';
  darkTheme: boolean = JSON.parse(<string>localStorage.getItem("user-dark-theme")) ?? true;
  homeBanner: boolean = JSON.parse(<string>localStorage.getItem("user-home-banner")) ?? true;

  // Mutations modify state.
  @Mutation
  SET_AUTH_TOKEN(authToken: string) {
    this.authToken = authToken;
  }

  @Mutation
  SET_REFRESH_TOKEN(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  @Mutation
  SET_USER(user: Investor) {
    this.user = user;
  }

  @Mutation
  LOGOUT() {
    this.user = {} as Investor;
    this.authToken = '';
    this.refreshToken = '';
  }

  @Mutation
  SET_USER_DARK_THEME(darkTheme: boolean) {
    this.darkTheme = darkTheme;
  }

  @Mutation
  SET_USER_HOME_BANNER(showHomeBanner: boolean) {
    this.homeBanner = showHomeBanner;
  }

  // Actions call service methods and make commit() calls to trigger mutations.
  // Actions can also trigger other actions asynchronously using dispatch() calls.
  @Action
  async registerAndLogin(registerFormData: RegisterFormData): Promise<Responses.GetMe>  {
    const loginResponse = await AuthService.register(registerFormData).catch(
      err => {
        localStorage.setItem("auth-token", "");
        localStorage.setItem("refresh-token", "");
        this.context.commit("LOGOUT");
        return Promise.reject(err);
      }
    )

    const loginForm: LoginFormData = {
      usernameOrEmail: registerFormData.username,
      plaintextPassword: registerFormData.plaintextPassword
    }

    return this.context.dispatch("login", loginForm);
  }

  @Action
  async login(loginFormData: LoginFormData): Promise<Responses.GetMe>  {
    const loginResponse = await AuthService.login(loginFormData).catch(
      err => {
        localStorage.setItem("auth-token", "");
        localStorage.setItem("refresh-token", "");
        this.context.commit("LOGOUT");
        return Promise.reject(err);
      }
    );

    const authToken = loginResponse.data.authToken;
    const refreshToken = loginResponse.data.refreshToken;
    localStorage.setItem("auth-token", authToken);
    localStorage.setItem("refresh-token", refreshToken);
    this.context.commit("SET_AUTH_TOKEN", authToken);
    this.context.commit("SET_REFRESH_TOKEN", refreshToken);
    return this.context.dispatch("fetchMe");
  }

  @Action
  async fetchMe(): Promise<Responses.GetMe> {
    const userResponse = await InvestorService.getMe().catch(
      err => {
        return Promise.reject(err);
      }
    );

    if (userResponse.status === 200) {
      this.context.commit("SET_USER", userResponse.data);
      return Promise.resolve(userResponse.data);
    }

    return Promise.reject(userResponse.statusText);
  }

  @Action
  async updateTokens(payload: Responses.RefreshToken) {
    localStorage.setItem("auth-token", payload.authToken);
    localStorage.setItem("refresh-token", payload.refreshToken);
    this.context.commit("SET_AUTH_TOKEN", payload.authToken);
    this.context.commit("SET_REFRESH_TOKEN", payload.refreshToken);
  }

  @Action
  async logout() {
    // await AuthService.logout();
    this.context.commit("LOGOUT");
    localStorage.removeItem("auth-token");
    localStorage.removeItem("refresh-token");
  }

  @Action
  async userDarkTheme(darkTheme: boolean) {
    this.context.commit("SET_USER_DARK_THEME", darkTheme);
    localStorage.setItem("user-dark-theme", darkTheme.toString())
  }

  @Action
  async userHomeBanner(showHomeBanner: boolean) {
    this.context.commit("SET_USER_HOME_BANNER", showHomeBanner);
    localStorage.setItem("user-home-banner", showHomeBanner.toString())
  }

  get getIsLoggedIn(): boolean {
    return !isEmpty(this.user);
  }

  get getUser(): Investor {
    return this.user;
  }

  get getAuthToken(): string {
    return this.authToken;
  }

  get getRefreshToken(): string {
    return this.refreshToken;
  }

  get getIsDarkTheme(): boolean {
    return this.darkTheme;
  }

  get getShowHomeBanner(): boolean {
    return this.homeBanner;
  }
}