import Vue from "vue";
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
const dynamicThemes: { [name: string]: {[name: string]: string | object}} = {
    'light': {
        'primary': colors.purple,
        'secondary': colors.grey.lighten4,
        'tertiary': colors.grey.lighten3,
        'accent': colors.shades.black,
        'error': colors.red.lighten1,
        'background': colors.indigo.lighten5,
        'alternate': colors.grey.darken4,
        'happy': colors.green.lighten1,
        'sad': colors.red.lighten1,
    },
    'dark': {
        'primary': colors.yellow.lighten1,
        'secondary': colors.grey.darken4,
        'tertiary': colors.grey.darken3,
        'accent': colors.shades.black,
        'error': colors.red.lighten1,
        'background': colors.indigo.base,
        'alternate': colors.grey.lighten4,
        'happy': colors.green.lighten1,
        'sad': colors.red.lighten1,
    }
};

/* create inverted dark theme palette */
Object.keys(dynamicThemes.dark).forEach(function(name: string) {
    const invertedColour = dynamicThemes.light[name];
    if (!invertedColour) {
        return console.error(`Failed to create inverted colour for dark theme! The light theme is missing a colour named '${name}'.`)
    }
    dynamicThemes.dark[name + "-inverted"] = invertedColour;
});

/* create inverted light theme palette */
Object.keys(dynamicThemes.light).forEach(function(name) {
    const invertedColour = dynamicThemes.dark[name];
    if (!invertedColour) {
        return console.error(`Failed to create inverted colour for light theme! The dark theme is missing a colour named '${name}'.`)
    }
    dynamicThemes.light[name + "-inverted"] = invertedColour;
});

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: true,
        themes: dynamicThemes
    }
});