<template>
  <v-layout>
    <v-col sm="11">
      <v-row class="pt-2" align="end">
        <v-skeleton-loader v-if="!symbol" type="image" width="80" height="40"/>
        <h1 v-else style="display:inline; font-weight:900">{{symbol}}</h1>
        <h1 class="px-2" style="display:inline; font-weight:300">|</h1>
        <v-skeleton-loader v-if="!name" type="image" width="300" height="40"/>
        <h1 v-else style="display:inline; font-weight:400">{{name}}</h1>
      </v-row>

      <v-row class="py-0" align="end">
        <h1 v-if="currentPrice">${{currentPrice}}</h1>
        <v-skeleton-loader v-else type="image" width="110" height="40" class="mt-3 mr-3"/>

        <v-col v-if="this.deltaPrice && this.deltaPricePct" justify="left" class="pb-2">
          <h4 v-if="this.deltaPrice > 0.001" class="happy-text">
            <v-icon color="happy" class="pb-1">mdi-trending-up</v-icon>
            ${{this.deltaPrice}} ({{this.deltaPricePct}}%) {{this.timeFrame}}
          </h4>
          <h4 v-else-if="this.deltaPrice < -0.001" class="sad-text">
            <v-icon color="sad" class="pb-1">mdi-trending-down</v-icon>
            -${{Math.abs(this.deltaPrice)}} ({{this.deltaPricePct}}%) {{this.timeFrame}}
          </h4>
          <h4 v-else>{{this.deltaPrice}} ({{this.deltaPricePct}}%) {{this.timeFrame}}</h4>
        </v-col>

        <v-skeleton-loader v-else type="image" width="200" height="30" class="mt-3"/>
      </v-row>
      <v-row class="pa-0">
        <p class="small-font my-0 py-0"><i>{{sector}} {{assetType}}</i></p>
      </v-row>
    </v-col>

    <v-col sm="1" justify="right">
<!--      Other shit on the right hand side of header, perhaps logos?-->
    </v-col>

  </v-layout>
</template>

<script>
  import store from "../../store"

  export default {
    store,
    props: {
      close: {required: true},
      previousClose: {required: true},
      symbol: {required: true},
      name: {required: true},
      sector: {required: true},
      assetType: {required: true},
    },
    data() {
      return {
        timeFrame: "today",
      }
    },
    computed: {
      currentPrice() {
        if (this.close) {
          return this.close.toFixed(2)
        }
        return 0.0;
      },
      deltaPrice() {
        if (this.close && this.previousClose) {
          return (this.close - this.previousClose).toFixed(2);
        }
        return 0.0;
      },
      deltaPricePct() {
        if (this.deltaPrice && this.previousClose) {
          return ((this.deltaPrice / this.previousClose) * 100).toFixed(2);
        }
        return 0.0;
      }
    }
  }
</script>

<style scoped>
  .v-skeleton-loader__card-heading v-skeleton-loader__bone {
    background: none;
  }
</style>