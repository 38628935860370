import { VuexModule, Mutation, Action, Module } from 'vuex-module-decorators'

@Module
export default class Snackbar extends VuexModule {

  // State.
  text: string = 'Default Text';
  color: string = 'green';
  time: number = 3500;

  // Mutations modify state.
  @Mutation
  public SET_SNACKBAR(snack: { [name: string]: any }): void {
    this.text = snack.text;
    this.color = snack.color;
    this.time = snack.time;
  }

  @Mutation
  public REMOVE_SNACKBAR(): void {
    this.text = '';
  }

  // Actions call service methods and make commit() calls to trigger mutations.
  // Actions can also trigger other actions asynchronously using dispatch() calls.
  @Action({ commit: 'REMOVE_SNACKBAR' })
  public resetSnackbar(): void {
    return;
  }

  @Action({ commit: 'SET_SNACKBAR' })
  public setSnackbar(snack: { [name: string]: any }): { [name: string]: any }  {
    return snack;
  }

  get getSnackbarColor(): string {
    return this.color;
  }

  get getSnackbarText(): string {
    return this.text;
  }

  get getSnackbarTime(): number {
    return this.time;
  }
}