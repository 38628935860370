<template>
  <div>
    <LoginDialog :closeLoginCb="closeLoginDialog" :loginDialogOpen="loginDialogOpen" :switchToRegisterDialogCb="switchDialog"/>
    <RegisterDialog :closeRegisterCb="closeRegisterDialog" :registerDialogOpen="registerDialogOpen" :switchToLoginDialogCb="switchDialog"/>
  </div>
</template>

<script>
import store from "@/store";
import RegisterDialog from "@/components/auth/RegisterDialog";
import LoginDialog from "@/components/auth/LoginDialog";
import {goToRoute, routes} from "@/router";
import {EventBus, events} from "@/event-bus";

export default {
  store,
  components: {RegisterDialog, LoginDialog},
  data() {
    return {
      loginDialogOpen: false,
      registerDialogOpen: false,
    };
  },
  mounted() {
    EventBus.$on(events.OPEN_LOGIN_DIALOG, this.openLoginDialog);
    EventBus.$on(events.OPEN_REGISTER_DIALOG, this.openRegisterDialog);
  },
  methods: {
    openLoginDialog() {
      this.loginDialogOpen = true;
    },

    closeLoginDialog() {
      this.loginDialogOpen = false;
    },

    openRegisterDialog() {
      this.registerDialogOpen = true;
    },

    closeRegisterDialog() {
      this.registerDialogOpen = false;
    },

    switchDialog() {
      this.loginDialogOpen = !this.loginDialogOpen;
      this.registerDialogOpen = !this.registerDialogOpen;
    },

    goToMyProfile() {
      goToRoute(routes.INVESTOR_PROFILE)
    }
  },
}
</script>

<style scoped>

</style>