import InstrumentClient, { Requests, Responses } from "@/clients/instrument.client";
import {AxiosResponse} from "axios";

class InstrumentService {
  async search(query: string): Promise<Responses.InstrumentSummary[]> {
    if (!query){
      return Promise.reject("Search query was empty");
    }

    const searchRequest: Requests.Search = {
      query: query,
      limit: 10
    }
    const response = await InstrumentClient.search(searchRequest);

    // No search results.
    if (response.status === 204)
      return Promise.resolve([]);

    // Success!
    if (response.status === 200)
      return Promise.resolve(response.data.results);

    // Some search failure occurred.
    return Promise.reject("Search failed");
  }

  async getAnalystRatings(symbol: string): Promise<Responses.AnalystRating[]> {
    if (!symbol){
      return Promise.reject("Requested symbol was empty");
    }

    const analystRatingsRequest: Requests.AnalystRatings = {
      symbol: symbol
    }
    const response = await InstrumentClient.analystRatingsForSymbol(analystRatingsRequest);

    // No analyst rating results.
    if (response.status === 204)
      return Promise.resolve([]);

    // Success!
    if (response.status === 200)
      return Promise.resolve(response.data.Ratings);

    // Some failure occurred.
    return Promise.reject("Get analyst ratings failed");
  }

  async getDetails(symbol: string): Promise<Responses.InstrumentDetails> {
    if (!symbol){
      return Promise.reject("Requested symbol was empty");
    }

    const detailsRequest: Requests.SymbolDetails = {
      symbol: symbol
    }
    const response = await InstrumentClient.detailsForSymbol(detailsRequest);

    // Success!
    if (response.status === 200)
      return Promise.resolve(response.data);

    // Some failure occurred.
    return Promise.reject("Get symbol details failed");
  }

  async getQuote(symbols: string[]): Promise<Responses.Quote[]> {
    if (!symbols || symbols.length < 1){
      return Promise.reject("Requested symbols were empty");
    }

    const detailsRequest: Requests.Quote = {
      symbols: symbols
    }
    const response = await InstrumentClient.quoteForSymbol(detailsRequest);

    // Success!
    if (response.status === 200)
      return Promise.resolve(response.data.marketDataList);

    // Some failure occurred.
    return Promise.reject("Get quote for symbols failed");
  }

  async trending(): Promise<Responses.InstrumentSummary[]> {
    const trendingRequest: Requests.Trending = {
      limit: 5
    }
    const response = await InstrumentClient.trending(trendingRequest);

    // No search results.
    if (response.status === 204)
      return Promise.resolve([]);

    // Success!
    if (response.status === 200)
      return Promise.resolve(response.data.results);

    // Some search failure occurred.
    return Promise.reject("Search failed");
  }

  static formatSymbol(symbol: string) {
    if (symbol.includes('.')) {
      const symbolName = (symbol.split('.')[0]).toUpperCase();
      const exchangeName = (symbol.split('.')[1]).toLowerCase();
      return `${symbolName}.${exchangeName}`;
    }
    return symbol.toUpperCase();
  }
}

export default new InstrumentService();