<template>
  <v-container class="py-5">
    <h1>{{ stock.symbol }}</h1>
    <h3 class="font-weight-thin">{{ stock.name }}</h3>
  </v-container>
</template>

<script>
  export default {
    props: {
      stock: {required: true},
      quote: {required: true}
    },
    data() {
      return {

      }
    },
    mounted() {

    },
    computed: {

    }
  }
</script>