<template>
  <v-container class="pa-0">
    <v-divider class="pa-0" v-if="index !== 0" :key="`${index}-divider`"/>

    <v-container class="hoverable px-1 py-4">
      <v-row align="center" @click="navigateTo(symbol)">
        <v-col sm="4" class="ml-2 py-0 mr-3" justify="left">
          <v-skeleton-loader v-if="!symbol" type="image" height="20px"/>
          <span v-else v-html="niceSymbol"></span>
        </v-col>

        <v-col v-if="this.currentPrice !== undefined && this.deltaPrice !== undefined" class="pa-0">
          <h4 class="number-text-style">${{this.currentPrice}}</h4>
          <h5 v-if="this.deltaPrice > 0.001" class="happy-text number-text-style">+${{this.deltaPrice}} ({{this.deltaPricePct}}%)</h5>
          <h5 v-else-if="this.deltaPrice < -0.001" class="sad-text number-text-style">-${{Math.abs(this.deltaPrice)}} ({{this.deltaPricePct}}%)</h5>
          <h5 v-else>{{this.deltaPrice}} ({{this.deltaPricePct}}%)</h5>
        </v-col>

        <v-col v-else class="pb-1 pt-1 pl-1">
          <v-skeleton-loader type="image" height="20px"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import { goToCustomRoute } from "@/router"

  export default {
    props: {
      symbol: {required: true, type: String},
      quote: {required: true },
      index: {required: true, type: Number}
    },
    data() {
      return {
        currentPrice: undefined,
        deltaPrice: undefined,
        deltaPricePct: undefined
      }
    },
    mounted() {
      if (this.quote != null) {
        this.symbol = this.quote.symbol;
        this.determineHappiness();
      }
    },
    methods: {
      determineHappiness() {
        let previousClosePrice = this.quote.priorClose;
        this.currentPrice = this.quote.close.toFixed(2);
        this.deltaPrice = (this.quote.close - previousClosePrice).toFixed(2);
        this.deltaPricePct = ((this.deltaPrice / previousClosePrice) * 100).toFixed(2);
      },

      navigateTo(symbol) {
        goToCustomRoute(`instruments/${symbol.toLowerCase()}`);
      }
    },
    computed: {
      niceSymbol() {
        if (this.symbol.includes('.')) {
          let symbolName = this.symbol.split('.')[0];
          let exchangeName = this.symbol.split('.')[1];
          return `<h4 class="symbol-text-style" style="display: inline">${symbolName}</h4><h5 class="symbol-text-style" style="display: inline">.${exchangeName}</h5>`;
        }
        return `<h3 class="symbol-text-style">${this.symbol}</h3>`;
      }
    }
  }
</script>

<style scoped>
  .hoverable:hover {
    transition: background .1s ease-in;
    background-color: var(--v-tertiary-base);
    cursor: pointer;
  }
</style>