<template>
  <v-container>
    <v-row class="mb-2">
      <h3><v-icon left class="mb-1">mdi-cash-multiple</v-icon>Your Trades</h3>
    </v-row>

    <v-row>
      <v-container>
        <v-row v-for="(transaction, index) in transactions" :key="index">
          <p>{{transaction}}</p>
        </v-row>
      </v-container>
    </v-row>

    <v-row>
      <v-btn v-if="$vuetify.theme.dark" light @click="openDialog()">
        <v-icon left>mdi-plus</v-icon>Add Transaction
      </v-btn>
      <v-btn v-else dark @click="openDialog()">
        <v-icon left>mdi-plus</v-icon>Add Transaction
      </v-btn>
    </v-row>

    <TransactionDialog :completeTransactionCb="completeTransaction" :dialogOpen="dialogOpen"></TransactionDialog>
  </v-container>
</template>

<script>
  import TransactionDialog from "../transaction/TransactionDialog";

  export default {
    components: {TransactionDialog},
    data() {
      return {
        transactions: [
            "yeet", "skeet", "bleet", "feet"
        ],
        dialogOpen: false
      }
    },
    methods: {
      openDialog() {
        this.dialogOpen = true;
      },

      closeDialog() {
        this.dialogOpen = false;
      },

      completeTransaction() {
        // finalise transaction
        this.closeDialog();
      }
    }
  }
</script>

<style scoped>

</style>