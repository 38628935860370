<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
<!--    <v-tooltip bottom>-->
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-btn color="sad" fab small v-bind="attrs" v-on="on" @click="stopTrackingTicker">-->
<!--          <v-icon>mdi-delete</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--      <span>Stop tracking</span>-->
<!--    </v-tooltip>-->
  </v-container>
</template>

<script>
  export default {
    props: {
      stock: {required: true}
    },
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style scoped>

</style>