import AuthClient, { Requests } from "@/clients/auth.client";
import {LoginFormData, RegisterFormData} from "@/common/types";

class AuthService {
  async login(loginFormData: LoginFormData) {
    const loginRequest: Requests.Login = {
      usernameOrEmail: loginFormData.usernameOrEmail,
      plaintextPassword: loginFormData.plaintextPassword
    }
    return AuthClient.login(loginRequest);
  }

  async register(registerFormData: RegisterFormData) {
    const registerRequest: Requests.Register = {
      username: registerFormData.username,
      email: registerFormData.email,
      plaintextPassword: registerFormData.plaintextPassword
    }
    return AuthClient.register(registerRequest);
  }

  async refreshToken(payload: any) {
    return AuthClient.refreshToken(payload);
  }

  async logout() {
    return AuthClient.logout();
  }
}

export default new AuthService();
