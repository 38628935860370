<template>
  <VueApexCharts ref="chart" style="padding-top: 10px" :height="height" :width="width" :options="options" :series="series" :type="type"></VueApexCharts>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";

  export default {
    components: {
      VueApexCharts
    },
    props: {
      options: {
        required: true,
        type: Object,
      },
      series: {
        required: true,
        type: Array,
      },
      width: {
        default: undefined,
        type: Number,
      },
      height: {
        default: undefined,
        type: Number,
      },
      type: {
        default: 'line',
        type: String,
      }
    }
  };
</script>