<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app-bar app flat dense>
    <v-container>
      <v-row style="align-items:center; justify-content:space-between">
        <v-col class="nav-container" cols="7" sm="6" md="5">
          <span v-for="nav in navRoutes" :key="nav.id" class="mr-5">
            <a @click="navigateTo(nav.routeName)" :class="isCurrentRoute(nav.routeName) ? 'nav-route selected' : 'nav-route'">
              {{ nav.name }}
            </a>
          </span>
        </v-col>

        <v-col class="logo-container" cols="1" sm="1" md="2">
          <a @click="goHome">
            <v-container pa-0 ma-0 text-center>
              <img v-if="$vuetify.theme.dark" style="height: 25px" alt="STOCKSLIP Logo" src="../../assets/stockslip-alt-white.png">
              <img v-else style="height: 25px" alt="STOCKSLIP Logo" src="../../assets/stockslip-alt-black.png">
            </v-container>
          </a>
        </v-col>

        <v-col class="icon-container pa-0" cols="3" sm="5" md="5">
          <span>
            <v-menu :close-on-content-click="false" :nudge-width="100" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$vuetify.theme.dark" icon color="white" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
                <v-btn v-else icon color="black" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn v-if="!$vuetify.theme.dark" dark x-small fab @click="darkMode">
                        <v-icon class="mr-1" light>mdi-moon-waxing-crescent</v-icon>
                      </v-btn>
                      <v-btn v-else light x-small fab @click="darkMode">
                        <v-icon dark>mdi-white-balance-sunny</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title v-if="!$vuetify.theme.dark">Night mode</v-list-item-title>
                    <v-list-item-title v-else>Burn your retinas mode</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-btn v-if="showHomeBanner" dark x-small fab @click="toggleBanner">
                        <v-icon light>mdi-eye-off</v-icon>
                      </v-btn>
                      <v-btn v-else light x-small fab @click="toggleBanner">
                        <v-icon dark>mdi-party-popper</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title v-if="showHomeBanner">Hide home banner</v-list-item-title>
                    <v-list-item-title v-else>Show home banner</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>

            <v-btn v-if="showLoginButton" centered text @click="openLoginDialog">
              Login  <v-icon right color="happy">mdi-login</v-icon>
            </v-btn>

            <span v-else>
              <v-btn v-if="$vuetify.theme.dark" icon color="white" @click="goToMyProfile">
                <v-icon>mdi-account-tie</v-icon>
              </v-btn>
              <v-btn v-else icon color="black" @click="goToMyProfile">
                <v-icon>mdi-account-tie</v-icon>
              </v-btn>
            </span>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
  import store from "@/store"
  import {EventBus, events} from "@/event-bus";
  import {goToRoute, routes} from "@/router"

  export default {
    store,
    events,
    EventBus,
    data() {
      return {
        activeTab: `/user/${this.id}`,
        navRoutes: [
          { id: 1, name: "HOME", routeName: routes.HOME },
          { id: 2, name: "SEARCH", routeName: routes.STOCK_SEARCH },
          { id: 3, name: "ABOUT", routeName: routes.ABOUT }
        ]
      };
    },
    computed: {
      showLoginButton: function () {
        return !store.getters.getIsLoggedIn;
      },
      showHomeBanner: function() {
        return store.getters.getShowHomeBanner;
      },
    },
    methods: {
      darkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        store.dispatch("userDarkTheme", this.$vuetify.theme.dark)
      },

      goToMyProfile() {
        goToRoute(routes.INVESTOR_PROFILE)
      },

      goHome() {
        goToRoute(routes.HOME);
      },

      openLoginDialog () {
        EventBus.$emit(events.OPEN_LOGIN_DIALOG)
      },

      toggleBanner() {
        store.dispatch("userHomeBanner", !store.getters.getShowHomeBanner)
      },

      navigateTo(routeName) {
        goToRoute(routeName);
      },

      isCurrentRoute(routeName) {
        return this.$route.name === routeName;
      }
    },
  }
</script>

<style scoped>
  li a {
    text-decoration: none;
    color: inherit;
  }

  .nav-container {
    text-align: left;
  }

  .nav-route {
    color: darkgray;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 18px;
    font-family: "Space Grotesk", sans-serif;
  }

  .nav-route:hover {
    color: gray;
  }

  .selected {
    color: inherit;
    font-weight: 800;
  }

  .selected:hover {
    color: inherit;
  }

  .logo-container {
    text-align: center;
    align-self: flex-end;
    padding-top: 20px;
  }

  .icon-container {
    text-align: right;
  }
</style>