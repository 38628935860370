<template>
  <v-main>
    <v-container fill-height  >
      <v-row align="center" justify="center">
        <v-col >
          <h1 ref="coolTitle3" style="font-size: 24px;">this is an about page</h1>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    data() {
      return {};
    },
    mounted() {
      if (this.$anime) {
        this.$anime.timeline().add({
          targets: this.$refs.coolTitle3,
          translateY: [100, 50],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        })
      }
    },
  }
</script>

<style scoped>
  .ml16 {
    overflow: hidden;
  }
</style>
