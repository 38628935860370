<template>
  <v-container class="pa-0">
    <p> {{transactions.length}} transactions loaded.</p>
    <ApexChart ref="chart" :options="chartOptions" :series="chartSeries"></ApexChart>
  </v-container>
</template>

<script>
  import ApexChart from "@/components/charting/ApexChart";

  export default {
    components: {
      ApexChart,
    },
    props: {
      transactions: {required: true},
    },
    data() {
      return {
        chartSeries: [{
          name: 'Westpac Spending',
          data: this.generateBalanceTimeSeriesFromTransactions()
        }],
        chartOptions: {
          theme: {
            mode: 'dark',
            palette: 'palette4',
          },
          chart: {
            zoom: {
              enabled: true,
              type: 'x',
              autoScaleYaxis: true,
            },
            toolbar: {
              tools: {
                download: false
              },
              show: true
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            tooltip: {
              enabled: false
            },
            type: 'datetime',
            tickAmount: 6,
            labels: {
              show: true,
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto Mono',
                fontWeight: 400,
              },
            }
          },
          yaxis: {
            tooltip: {
              enabled: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto Mono',
                fontWeight: 400,
              },
              formatter: (value) => {
                const formatter = Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
                return formatter.format(value)
              },
            },
            title: {
              text: "Transaction Amount (NZD)",
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto Mono',
                fontWeight: 600,
              },
            },
            opposite: true
          },
          tooltip: {
            custom: function({seriesIndex, dataPointIndex, w}) {
              const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const formatter = Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              return '<ul>' +
                '<li><b>Date</b>: ' + `${new Date(data.x).toDateString()}` + '</li>' +
                '<li><b>Balance</b>: ' + `${formatter.format(data.y)}` + '</li>' +
              '</ul>';
            }
          }
        },
      }
    },
    methods: {
      generateBalanceTimeSeriesFromTransactions() {
        const sortedTransactions = this.transactions
          .map(tx => tx.Transaction)
          .sort(function(a, b) {
            return a.Date > b.Date? 1 : a.Date < b.Date? -1 : 0
          })
        const minDate = new Date(sortedTransactions[0].Date);
        const maxDate = new Date(sortedTransactions[sortedTransactions.length-1].Date);
        const dateRange = this.getDatesBetween(minDate, maxDate);

        const timeSeries = [];
        let balance = 0;
        let txIndex = 0;
        for (const dateIndex in dateRange) {
          const allTransactionsOnThisDate = []
          while (txIndex < sortedTransactions.length && dateRange[dateIndex].toDateString() === new Date(sortedTransactions[txIndex].Date).toDateString()) {
            allTransactionsOnThisDate.push(sortedTransactions[txIndex])
            txIndex++;
          }
          let sumForDate = allTransactionsOnThisDate.map(tx => tx.Amount).reduce((a, b) => a + b, 0);
          balance += (sumForDate/100); // convert from cents to dollars
          timeSeries.push({x: dateRange[dateIndex], y: balance})
        }
        return timeSeries
      },

      // Returns an array of dates between the two dates
      getDatesBetween(startDate, endDate) {
        const dates = [];

        // Strip hours minutes seconds etc.
        let currentDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );

        while (currentDate <= endDate) {
          dates.push(currentDate);

          currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 1, // Will increase month if over range
          );
        }
        return dates;
      }
    }
  }
</script>