<template>
  <v-container>
    <v-slide-y-transition v-if="this.quotes != null" class="py-0" group >
      <AssetDayPerformance v-for="(symbol, index) in symbols" :key="index" :symbol="symbol" :index="index" :quote="quoteForSymbol(symbol)"/>
    </v-slide-y-transition>

<!--    Client isn't tracking any instruments -->
    <v-row v-else class="pt-3 helper-text" justify="center" align="center">
      <v-img src="../../../public/stock-portfolio.svg" height="120" contain class="mt-3 mb-5"/>

      <v-container v-if="clientLoggedIn">
        <p>you're not tracking any stocks :(<br><a @click="goToSearch">add some!</a></p>
      </v-container>

      <v-container v-else class="px-5">
        <p class="helper-text">
          <a @click="openLoginDialog">Login</a> or <a @click="openRegisterDialog">register</a> to start tracking stocks
        </p>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  import AssetDayPerformance from "./AssetDayPerformance";
  import store from "@/store";
  import {EventBus, events} from "@/event-bus";
  import { goToRoute, routes } from "@/router"
  // import InstrumentService from "@/services/instrument.service";

  export default {
    props: {
      symbols: {required: true, type: Array},
    },
    data() {
      return {
        quotes: null,
      }
    },
    components: {AssetDayPerformance},
    computed: {
      clientLoggedIn: function () {
        return store.getters.getIsLoggedIn;
      },
    },
    methods: {
      // eslint-disable-next-line no-unused-vars
      async getQuotesForSymbols(symbols) {
        // TODO (flynn): Switch back to fetching actual data once caching is sorted.
        // this.quotes = await InstrumentService.getQuote(symbols);

        let portfolioQuotes = [
          {
            ask: 86.82,
            bid: 86.82,
            close: 86.79,
            lastTrade: 86.79,
            priorClose: 87.69,
            symbol: "BABA",
          },
          {
            ask: 6.625,
            bid: 6.625,
            close: 6.63,
            lastTrade: 6.63,
            priorClose: 6.66,
            symbol: "GPRO",
          },
          {
            ask: 206.98,
            bid: 206.98,
            close: 206.98,
            lastTrade: 206.98,
            priorClose: 205.12,
            symbol: "HSY",
          },
          {
            ask: 0.772,
            bid: 0.772,
            close: 0.7711,
            lastTrade: 0.7711,
            priorClose: 0.7118,
            symbol: "MNMD",
          },
          {
            ask: 8.09,
            bid: 8.09,
            close: 8.08,
            lastTrade: 8.08,
            priorClose: 8.31,
            symbol: "PLTR",
          },
          {
            ask: 4.72,
            bid: 4.72,
            close: 4.73,
            lastTrade: 4.73,
            priorClose: 4.75,
            symbol: "RKLB",
          },
        ]

        let watchlistQuotes = [
          {
            ask: 167.73,
            bid: 167.73,
            close: 166.94,
            lastTrade: 166.94,
            priorClose: 171.24,
            symbol: "NVDA",
          },
          {
            ask: 93.46,
            bid: 93.46,
            close: 93.5,
            lastTrade: 93.5,
            priorClose: 96.67,
            symbol: "AMD",
          },
          {
            ask: 2182.01,
            bid: 2182.01,
            close: 2186.26,
            lastTrade: 2186.26,
            priorClose: 2214.91,
            symbol: "GOOG",
          },
          {
            ask: 665.9,
            bid: 665.9,
            close: 663.9,
            lastTrade: 663.9,
            priorClose: 709.42,
            symbol: "TSLA",
          },
        ]

        // TODO (flynn): remove this once we're using actual data (obviously)
        if (symbols.some(symbol => symbol === "PLTR"))
          this.quotes = portfolioQuotes;
        else
          this.quotes = watchlistQuotes;
      },

      openLoginDialog() {
        EventBus.$emit(events.OPEN_LOGIN_DIALOG);
      },

      openRegisterDialog() {
        EventBus.$emit(events.OPEN_REGISTER_DIALOG);
      },

      goToSearch() {
        goToRoute(routes.STOCK_SEARCH)
      },

      quoteForSymbol(symbol) {
        if (this.quotes.length < 1) return null;
        return this.quotes.find(x => x.symbol === symbol) ?? null;
      }
    },
    async mounted() {
      if (this.symbols.length > 0) {
        await this.getQuotesForSymbols(this.symbols);
      }
    }
  }
</script>

<style scoped>
  .helper-text p {
    font-size: 15px;
    text-align: center;
    font-style: italic;
  }
</style>