import { AxiosResponse } from "axios";
import CoreClient from './core.client';

export interface InvestorApi {
  getTransactionHistory(): Promise<AxiosResponse<Responses.TransactionHistory[]>>;
}

export declare namespace Responses {
  interface TransactionHistory {
    Transaction: Transaction
    Num: string
    Payee: string
    Address: string[]
    AddressMessage: string
    Category: string
    Splits: Split[]
  }

  interface Transaction {
    Date: string
    Amount: number
    Memo: string
    Status: number
  }

  interface Split {
    Category: string
    Memo: string
    Amount: number
  }
}

export declare namespace Requests {
  interface TransactionHistory {

  }
}

class InvestorClient implements InvestorApi {
  async getTransactionHistory(): Promise<AxiosResponse<Responses.TransactionHistory[]>> {
    return CoreClient.client.get<Responses.TransactionHistory[]>("/DEBUG/parse-transaction-history");
  }
}

export default new InvestorClient();