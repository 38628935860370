<template>
  <v-main>
    <v-container fill-height  >
      <v-row align="center" justify="center">
        <v-col >
          <h1 ref="coolTitle3" style="font-size: 24px;">YOUR INVESTOR PROFILE</h1>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <AccountBalanceTimeSeries v-if="transactionHistory.length > 0" :transactions="transactionHistory"></AccountBalanceTimeSeries>
      </v-row>

  <!--    <v-row align="center" justify="center">-->
  <!--      <TransactionHistoryScatter v-if="transactionHistory.length > 0" :transactions="transactionHistory"></TransactionHistoryScatter>-->
  <!--    </v-row>-->

      <v-row align="center" justify="center">
        <v-btn @click="logout">
          logout
        </v-btn>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import InvestorService from "@/services/investor.service";
// import TransactionHistoryScatter from "@/components/charting/TransactionHistoryScatter";
import store from "@/store"
import AccountBalanceTimeSeries from "@/components/charting/AccountBalanceTimeSeries";
import { goToRoute, routes } from "@/router"

export default {
  store,
  components: {
    AccountBalanceTimeSeries,
    // TransactionHistoryScatter,
  },
  data() {
    return {
      transactionHistory: []
    };
  },
  methods: {
    async getTransactionHistory() {
      const response = await InvestorService.getTransactionHistory();
      if (!response.data) {
        const errorText = `Failed to fetch transaction history!`;
        this.showErrorSnackbar(errorText, 3000);
        return Promise.reject(errorText);
      }
      return Promise.resolve(response.data);
    },

    logout() {
      store.dispatch("logout")
      .then(() => {
        goToRoute(routes.HOME);
      })
      .catch(() => {
        goToRoute(routes.HOME);
      });
    }
  },
  async mounted() {
    this.transactionHistory = await this.getTransactionHistory();

    if (this.$anime) {
      this.$anime.timeline().add({
        targets: this.$refs.coolTitle3,
        translateY: [100, 50],
        easing: "easeOutExpo",
        duration: 1400,
        delay: (el, i) => 30 * i
      })
    }
  },
}
</script>