import axios, {AxiosInstance} from "axios";
import store from '@/store';

const protocol = "http";
const host = "localhost";
const port = ":3000";
const apiURL = `${protocol}://${host}${port}/api/v1/`;

class CoreClient {
  client: AxiosInstance = axios.create({
    baseURL: apiURL,
  })

  constructor() {
    this.client.interceptors.request.use(
      (config) => {
        const authToken = localStorage.getItem('auth-token');
        if (authToken) {
          config.headers['X-Authorization'] = authToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const storedRefreshToken = store.getters.getRefreshToken;
        if (!storedRefreshToken) {
          return Promise.reject("no refresh token");
        }
        const originalConfig = err.config;
        if (originalConfig.url !== "/login" && err.response) {
          // Check if response was access token expired
          if (
            err.response.status === 401 &&
            err.response.data == "expired token\n" &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;
            try {
              const refreshTokenRequest = {
                refreshToken: storedRefreshToken
              }
              const response = await this.client.post("/investors/refresh-token", refreshTokenRequest)
              await store.dispatch('updateTokens', response.data);
              return this.client(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
  }
}

export default new CoreClient();