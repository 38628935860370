<template>
  <v-container>
    <v-row justify="space-around">
      <v-menu
          nudge-bottom="45"
          :close-on-content-click="false"
          :open-on-click="true"
          :close-on-click="true"
          rounded="b-xl"
          v-model="viewSearchResults">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            placeholder="Search 9,400+ US stocks and ETFs..."
            solo
            @keyup.enter.native=searchForStockDebounced
            v-model.lazy="searchQuery"
            append-icon="mdi-magnify"
            v-bind="attrs"
            v-on="on"
            @blur="delayedBlur($event)"
            :loading="searching"
          ></v-text-field>
        </template>

        <v-list>
          <!-- Not actively searching, search query is populated, and no results returned -->
          <v-list-item v-if="!searching && searchQuery !== '' && noResults">
            <v-list-item-title>No results for that search query, try something different.</v-list-item-title>
          </v-list-item>

          <!-- Not actively searching, search query is empty, and no results -->
          <v-container v-if="!searching && searchQuery === ''">
            <span>
              <v-subheader>TRENDING STOCKS</v-subheader>
              <v-divider/>
            </span>
            <StockDropdownItem v-for="result in trendingResults" :key="result.symbol" :searchResultItem="result" @click="navigateToSymbol(result.symbol)"/>
          </v-container>

          <v-container v-if="!searching && searchQuery !== '' && !noResults">
            <span>
              <v-subheader>SEARCH RESULTS</v-subheader>
              <v-divider/>
            </span>
            <StockDropdownItem v-for="result in searchResults" :key="result.symbol" :searchResultItem="result" @click="navigateToSymbol(result.symbol)"/>
          </v-container>
        </v-list>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>
  import StockDropdownItem from "@/components/search/StockDropdownItem";
  import InstrumentService from "@/services/instrument.service";
  import _ from "lodash";
  import {goToCustomRoute} from "@/router";

  export default {
    components: {StockDropdownItem},
    data() {
      return {
        searchQuery: "",
        searchResults: [],
        searching: false,
        noResults: false,
        viewSearchResults: false,
        trendingResults: [],
      };
    },
    methods: {
      searchForStockDebounced: _.debounce(async function () {
        if (!this.searchQuery) {
          this.searchResults = [];
          return;
        }
        this.searching = true;
        this.searchResults = await InstrumentService.search(this.searchQuery);
        this.searching = false;
        this.noResults = this.searchResults.length < 1;
      }, 500),

      delayedBlur(event) {
        // We do this because otherwise click events on list items aren't registered.
        setTimeout(() => {
          // We don't want to close the search result list when the user favourites something.
          if (event?.relatedTarget?.id === "favourite-button")
            return;

          this.viewSearchResults = false;
        }, 100)
      },
      navigateToSymbol(symbol) {
        goToCustomRoute(`instruments/${symbol}`);
      },
    },
    async mounted() {
      if (this.$anime) {
        this.$anime.timeline().add({
          targets: this.$refs.coolTitle,
          opacity: [0,1],
          scale: [0.2, 1],
          duration: 800
        });
      }
      this.trendingResults = await InstrumentService.trending();
    },
    watch: {
      searchQuery: function () {
        this.viewSearchResults = true;
        this.searchForStockDebounced()
      }
    },
  }
</script>

<style scoped>

</style>