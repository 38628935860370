<template>
  <v-container class="pa-0">
    <h2 class="pt-3">Fundamentals</h2>
    <p v-for="(value, metric) in stakeData.fundamentalDataModel" :key="metric+value" class="small-font mb-0">{{metric}}: {{value}}</p>

    <h2 class="mt-5">Corporate Information</h2>
    <p class="small-font">{{stakeData.description}}</p>
  </v-container>
</template>

<script>
  export default {
    props: {
      stockDetails: {required: true}
    },
    data() {
      return {
      }
    },
    methods: {
    },
    computed: {
      stakeData() {
        return this.stockDetails;
      }
    }
  }
</script>

<style scoped>

</style>