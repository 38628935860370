<template>
  <v-dialog v-model="registerDialogOpen" width="500" persistent>
    <v-card shaped>
      <v-card-title class="secondary-inverted">
        <h2 v-if="$vuetify.theme.dark" style="color: black">Register</h2>
        <h2 v-else style="color: white">Register</h2>
        <v-spacer/>
        <v-btn v-if="$vuetify.theme.dark" icon color="black" @click="closeRegisterCb">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-else icon color="white" @click="closeRegisterCb">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="secondary-inverted pt-3">
        <h3 v-if="$vuetify.theme.dark" style="color: black">Let's get you started</h3>
        <h3 v-else style="color: white">Let's get you started</h3>
      </v-card-subtitle>

      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-if="this.registerProcessing"
      ></v-progress-linear>

      <v-card-text class=mt-5>
        <v-form ref="form_register" lazy-validation>
          <v-text-field
              ref="input_username"
              v-model="registerForm.username"
              prepend-icon="mdi-account"
              label="Username"
              :rules="this.rules.required"
              required
              v-on:keyup.enter="goToEmailInput"
          ></v-text-field>
          <v-text-field
              ref="input_email"
              v-model="registerForm.email"
              prepend-icon="mdi-email"
              label="Email"
              :rules="this.rules.email"
              required
              v-on:keyup.enter="goToPasswordInput"
          ></v-text-field>
          <v-text-field
              ref="input_password"
              v-model="registerForm.plaintextPassword"
              prepend-icon="mdi-lock"
              label="Password"
              required
              :rules="this.rules.required"
              type="password"
              v-on:keyup.enter="register"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-alert :value="registerAlert" color="error">Incorrect register credentials.</v-alert>

      <v-divider></v-divider>

      <v-card-actions>
        <a class="btn-shine" @click="switchToLoginDialogCb">i'm not new here</a>
        <v-spacer></v-spacer>
        <v-btn color="happy" class="btn-register" centered text @click="register">
          Register  <v-icon right color="happy">mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store"
import rules from "@/common/form-rules";

export default {
  store,
  props: {
    registerDialogOpen: {required: true},
    closeRegisterCb: {required: true},
    switchToLoginDialogCb: {required: true}
  },
  data() {
    return {
      rules,
      registerForm: {
        username: "",
        email: "",
        plaintextPassword: ""
      },
      registerAlert: false,
      registerProcessing: false
    }
  },
  methods: {
    register() {
      if (!this.$refs.form_register.validate()) {
        return
      }

      this.registerAlert = false
      this.registerProcessing = true;
      store.dispatch("registerAndLogin", this.registerForm)
        .then(() => {
          this.registerForm.username = "";
          this.registerForm.email = "";
          this.registerForm.plaintextPassword = "";
          this.$refs.form_register.resetValidation();
          this.closeRegisterCb();
        })
        .catch(() => this.registerAlert = true)
        .finally(() => this.registerProcessing = false);
    },
    goToEmailInput() {
      this.$refs.input_email.focus();
    },
    goToPasswordInput() {
      this.$refs.input_password.focus();
    }
  }
};
</script>

<style scoped lang="scss">
.btn-register {
  margin-right: 10px;
}

.btn-shine {
  padding: 0 48px;
  margin: -28px; // negative margin to keep padding for animation
  background: linear-gradient(to right, gray 0, gold 10%, gray 20%) 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  font-style: italic;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

</style>