import { AxiosResponse } from "axios";
import CoreClient from './core.client';

export interface InstrumentApi {
  search(payload: Requests.Search): Promise<AxiosResponse<Responses.Search>>;
}

class InstrumentClient implements InstrumentApi {
  async search(payload: Requests.Search): Promise<AxiosResponse<Responses.Search>> {
    return CoreClient.client.get<Responses.Search>("/instruments/search", {
      params: { ...payload }
    });
  }

  async analystRatingsForSymbol(payload: Requests.AnalystRatings): Promise<AxiosResponse<Responses.AnalystRatings>> {
    return CoreClient.client.get<Responses.AnalystRatings>("/instruments/analyst-ratings", {
      params: { ...payload }
    });
  }

  async detailsForSymbol(payload: Requests.SymbolDetails): Promise<AxiosResponse<Responses.InstrumentDetails>> {
    return CoreClient.client.get<Responses.InstrumentDetails>("/instruments/details", {
      params: { ...payload }
    });
  }

  async quoteForSymbol(payload: Requests.Quote): Promise<AxiosResponse<Responses.Quotes>> {
    return CoreClient.client.get<Responses.Quotes>("/instruments/quote", {
      params: { symbols: payload.symbols.join(',') }
    });
  }

  async trending(payload: Requests.Trending): Promise<AxiosResponse<Responses.Trending>> {
    return CoreClient.client.get<Responses.Trending>("/instruments/trending", {
      params: { ...payload }
    });
  }
}

export declare namespace Responses {
  interface Search {
    count: number,
    results: InstrumentSummary[]
  }

  interface Trending {
    count: number,
    results: InstrumentSummary[]
  }

  interface InstrumentSummary {
    cik: number,
    name: string,
    symbol: string,
  }

  interface InstrumentDetails {
    instrumentID: string;
    name: string;
    symbol: string;
    description: string;
    type: string;
    categoryStake: string;
    urlImage: string;
    sector: string;
    viewed: number;
    popularity: number;
    returnOnStock: string;
    close: number;
    priorClose: number;
    dailyReturnValue: number;
    dailyReturnPercentage: number;
    lastTraded: number;
    yearlyReturnValue: number;
    yearlyReturnPercentage: number;
    fundamentalDataModel: FundamentalDataModel;
    instrumentTags: string[];
    status: string;
    urlInvestor: string;
    urlInvestors: any;
    period: string;
    bought: number;
    watched: number;
  }

  interface FundamentalDataModel {
    fiftyTwoWeekLowPrice: string;
    fiftyTwoWeekHighPrice: string;
    openPrice: string;
    bidPrice: string;
    askPrice: string;
    lowPrice: string;
    highPrice: string;
    marketCap: string;
    sharesOutstanding: string;
    earningsPerShare: string;
    peRatio: string;
    dividendYield: string;
    cumulativeVolume: string;
  }

  interface Quotes {
    marketDataList: Quote[]
  }

  interface Quote {
    symbol: string;
    ask: number;
    bid: number;
    lastTrade: number;
    priorClose: number;
    close: number;
  }

  interface AnalystRatings {
    Ratings: AnalystRating[]
  }

  interface AnalystRating {
    id: string;
    date: string;
    time: string;
    ticker: string;
    exchange: string;
    name: string;
    analyst: string;
    currency: string;
    url: string;
    importance: number;
    notes: string;
    updated: number;
    action_pt: string;
    action_company: string;
    rating_current: string;
    pt_current: string;
    rating_prior: string;
    pt_prior: string;
    url_calendar: string;
    url_news: string;
    analyst_name: string;
  }
}

export declare namespace Requests {
  interface Search {
    query: string
    limit: number
  }

  interface AnalystRatings {
    symbol: string
  }

  interface SymbolDetails {
    symbol: string
  }

  interface Quote {
    symbols: string[]
  }

  interface Trending {
    limit: number
  }
}

export default new InstrumentClient();