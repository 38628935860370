import {AxiosResponse} from "axios";
import AuthClient, {Responses as AuthResponses} from "@/clients/auth.client";
import InvestorClient, {Responses as InvestorResponses} from "@/clients/investor.client";

class InvestorService {
  async getMe(): Promise<AxiosResponse<AuthResponses.GetMe>> {
    return AuthClient.getMe();
  }

  async getTransactionHistory(): Promise<AxiosResponse<InvestorResponses.TransactionHistory[]>> {
    return InvestorClient.getTransactionHistory();
  }
}

export default new InvestorService();