<template>
  <v-footer :padless="true" class="stockslip-footer transparent">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card flat tile class="transparent">
            <v-divider class="mt-5 mb-5"></v-divider>
            <v-card-text class="white--text pa-0">
              <v-row align="center" justify="center" class="mb-1">
                <v-col>
                  <v-container pa-0>
                    <img v-if="$vuetify.theme.dark" style="height: 25px" alt="STOCKSLIP Logo" src="../../assets/stockslip-alt-white.png">
                    <img v-else style="height: 25px" alt="STOCKSLIP Logo" src="../../assets/stockslip-alt-black.png">
                  </v-container>
                </v-col>

                <v-spacer></v-spacer>

                <v-btn
                  v-for="link in links"
                  :key="link.address"
                  color="white"
                  text
                  rounded
                  class="my-2"
                  @click="navigate(link.routeName)"
                >{{ link.name }}</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { goToRoute, routes } from "@/router"

export default {
  data: () => ({
    links: [
      {name: 'About', routeName: routes.ABOUT},
      {name: 'Team', routeName: routes.TEAM},
      {name: 'Privacy Policy', routeName: routes.PRIVACY_POLICY},
      {name: 'Disclaimers', routeName: routes.DISCLAIMERS},
      {name: 'Contact Us', routeName: routes.CONTACT_US},
    ],
  }),
  methods: {
    navigate(routeName) {
      goToRoute(routeName);
    }
  }
}
</script>


<style scoped>

.stockslip-footer {
  bottom: 0;
  position: absolute;
  width: 100%;
}

</style>