<template>
  <v-dialog v-model="dialogOpen" width="500" persistent>
    <v-card shaped>
      <v-card-title class="secondary-inverted">
        <h2 v-if="$vuetify.theme.dark" style="color: black">Add Transaction</h2>
        <h2 v-else style="color: white">Add Transaction</h2>
      </v-card-title>

<!--      <v-card-subtitle class="secondary-inverted pt-3">-->
<!--        <h3 v-if="$vuetify.theme.dark" style="color: black">{{activeSymbolData.symbol}}, {{activeSymbolData.data.name}}</h3>-->
<!--        <h3 v-else style="color: white">{{activeSymbolData.symbol}}, {{activeSymbolData.data.name}}</h3>-->
<!--      </v-card-subtitle>-->

      <v-card-text>
        <v-skeleton-loader class="pt-5" type="article, actions"></v-skeleton-loader>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="happy" centered text @click="completeTransactionCb">
          Confirm  <v-icon right color="happy">mdi-check</v-icon>
        </v-btn>
        <v-btn color="sad" centered text @click="completeTransactionCb">
          Cancel  <v-icon right color="sad">mdi-cancel</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import store from "../../store"

  export default {
    store,
    props: {
      dialogOpen: {required: true},
      completeTransactionCb: {required: true},
    },
    computed: {
      // activeSymbolData() {
      //   return this.$store.getters.getActiveSymbolData
      // }
    }
  };
</script>

<style scoped>

</style>
