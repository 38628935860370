const HOME = "Home";
const STOCK_SEARCH = "StockSearch";
const INSTRUMENT = "Instrument";
const ABOUT = "About";
const INVESTOR_PROFILE = "InvestorProfile";
const TEAM = "Team";
const PRIVACY_POLICY = "PrivacyPolicy";
const DISCLAIMERS = "Disclaimers";
const CONTACT_US = "ContactUs";

export default {
  HOME: HOME,
  STOCK_SEARCH: STOCK_SEARCH,
  INSTRUMENT: INSTRUMENT,
  ABOUT: ABOUT,
  INVESTOR_PROFILE: INVESTOR_PROFILE,
  TEAM: TEAM,
  PRIVACY_POLICY: PRIVACY_POLICY,
  DISCLAIMERS: DISCLAIMERS,
  CONTACT_US: CONTACT_US,
}