<template>
  <v-list-item class="search-result-row" @click="navigateToSymbol(searchResultItem.symbol)">
    <span class="search-result">
      <v-tooltip bottom open-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-icon id="favourite-button" class="pa-1" v-bind="attrs" v-on="on" @click="updateWatchlist(searchResultItem.symbol)" v-on:click.stop>
            mdi-heart-outline
          </v-icon>
        </template>
        <span>Add to watchlist</span>
      </v-tooltip>

      <span class="pl-3 ma-0 search-result-title">
        <p><b>{{ searchResultItem.symbol }}</b>
        <span class="mx-1"> | </span>
        {{ toTitleCase(searchResultItem.name) }}</p>
      </span>
    </span>

    <span class="search-result-navigate-button">
      <v-tooltip bottom open-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" @click="navigateToSymbol(searchResultItem.symbol)">
            mdi-arrow-right
          </v-icon>
        </template>
        <span>View details</span>
      </v-tooltip>
    </span>
  </v-list-item>
</template>

<script>
import _ from "lodash";
import {goToCustomRoute} from "@/router";

export default {
  props: {
    searchResultItem: {required: true},
  },
  methods: {
    toTitleCase(string) {
      return _.startCase(string.toLowerCase());
    },

    navigateToSymbol(symbol) {
      goToCustomRoute(`instruments/${symbol}`);
    },

    updateWatchlist(symbol) {
      // do something with the symbol
      console.log(symbol)
    },
  }
}
</script>

<style scoped>

.search-result-row {
  cursor: pointer;
  padding: 0 15px;
  margin: 0;
  justify-content: space-between;
}

.search-result-row:hover {
  background-color: #2a2a2a;
}

.search-result {
  display: flex;
  flex-direction: row;
}

.search-result-title {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-result-title p {
  margin: 0;
  font-size: 1em;
}

.search-result-navigate-button {
  display: flex;
  margin-left: auto;
}
</style>