import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies'
// @ts-ignore
import VueAnime from 'vue-animejs';
import ApexCharts from 'vue-apexcharts'
import SnackbarMixin from './components/mixins/SnackbarMixin.vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  logErrors: true,
  dsn: "https://7fc915c6d959497bac590114d5bf6352@o1256016.ingest.sentry.io/6424982",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "localhost:3000", "stocks.cantecco.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

// @ts-ignore
new Vue({
  router,
  store,
  vuetify,
  // @ts-ignore
  'options': {
    "customProperties": true
  },
  render: h => h(App)
}).$mount('#app');

Vue.use(VueCookies);
Vue.$cookies.config('100y');
Vue.use(VueAnime);
Vue.use(ApexCharts);
Vue.mixin(SnackbarMixin);
