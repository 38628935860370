<template>
  <v-container>
    <v-row>
      <v-col>
        <TwitterCard sentiment='positive' :message='messages[0]'/>
      </v-col>
      <v-col>
        <TwitterCard sentiment='positive' :message='messages[1]'/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TwitterCard sentiment='negative' :message='messages[2]'/>
      </v-col>
      <v-col>
        <TwitterCard sentiment='positive' :message='messages[3]'/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TwitterCard from "./TwitterCard";
  export default {
    components: {TwitterCard},
    data() {
      return {
        messages: [
          "We love the stock! SAY IT WITH ME! 🚀🚀🚀",
          "Can't believe RKLB is going to the moon this year, woot!",
          "Yeah, this bull market has gone on wayyy too long. 👎 #putgang",
          "Excited for earnings tomorrow, @elonmusk don't disappoint!!!"
        ]
      }
    }
  }
</script>

<style scoped>

</style>