<template>
  <v-container>

    <v-container v-if="loadingStockDetails" mb-5>
      <div class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
    </v-container>

    <v-container v-if="!loadingStockDetails && stockDetails === null">
      <p>Failed to get details for symbol :(</p>
    </v-container>

    <p v-if="stockDetails">{{ stockDetails }}</p>

    <v-divider/>

    <v-container v-if="loadingAnalystRatings" mb-5>
      <div class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
    </v-container>

    <v-container v-if="!loadingAnalystRatings && analystRatings === null">
      <p>Failed to get details for symbol :(</p>
    </v-container>

    <p v-if="analystRatings">{{ analystRatings }}</p>

  </v-container>
</template>

<script>
import InstrumentService from "@/services/instrument.service";

  export default {
    props: {
      stock: {required: true}
    },
    data() {
      return {
        loadingStockDetails: false,
        loadingAnalystRatings: false,
        stockDetails: null,
        analystRatings: null,
        trimmedSymbol: this.stock.symbol.split('.')[0]
      };
    },
    methods: {
      async loadSymbolDetails() {
        this.loadingStockDetails = true;
        this.stockDetails = await InstrumentService.getDetails(this.trimmedSymbol).finally(() => {
          this.loadingStockDetails = false
        });
      },

      async loadAnalystRatings() {
        this.loadingAnalystRatings = true;
        this.analystRatings = await InstrumentService.getAnalystRatings(this.trimmedSymbol).finally(() => {
          this.loadingAnalystRatings = false
        });
      }
    },
    mounted() {
      this.loadSymbolDetails();
      this.loadAnalystRatings();
    }
  }
</script>

<style scoped>

</style>