<template>
  <v-app>
    <v-main style="padding-bottom: 150px; padding-top: 20px">
      <Auth/>
      <FloatingTop/>
      <transition-page>
        <router-view />
      </transition-page>
      <Snackbar/>
    </v-main>
    <StockSlipFooter/>
  </v-app>
</template>

<script>
import TransitionPage from '@/components/general/TransitionPage'
import FloatingTop from '@/components/general/FloatingTop'
import Snackbar from '@/components/general/Snackbar'
import StockSlipFooter from '@/components/general/StockSlipFooter'
import Auth from "@/components/auth/Auth";
import store from '@/store';

const DEFAULT_TRANSITION = 'fade';

export default {
  names: 'App',
  store,
  components: {TransitionPage, FloatingTop, Snackbar, StockSlipFooter, Auth},
  data() {
    return {}
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }
      this.transitionName = transitionName || DEFAULT_TRANSITION;
      next();
    });

    this.$vuetify.theme.dark = store.getters.getIsDarkTheme;
  },
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,300;1,400;1,600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');
  $body-font: 'Overpass';
  $title-font: 'Space Grotesk';

  .v-application {
    font-family: $body-font, sans-serif !important;
    .title {
      font-family: $title-font, monospace !important;
    }

    @media (min-width: 960px) {
      .container {
        max-width: 1000px;
      }
    }

    @media (min-width: 1264px) {
      .container {
        max-width: 1100px;
      }
    }

    @media (min-width: 1904px) {
      .container {
        max-width: 1200px;
      }
    }

    h1 {
      font-family: $title-font, monospace !important;
      font-size: 1.8em;
    }

    h2 {
      font-family: $title-font, monospace !important;
      font-size: 1.4em;
      font-weight: 800;
    }

    p {
      font-size: 0.8em;
    }

    .number-text-style {
      font-family: $title-font, monospace !important;
    }

    .symbol-text-style {
      font-family: $title-font, monospace !important;
    }

    .small-font {
      font-size: 12px;
    }
    .happy-text {
      color: var(--v-happy-base);
    }
    .sad-text {
      color: var(--v-sad-base);
    }
  }
</style>
