<template>
  <v-dialog v-model="loginDialogOpen" width="500" persistent>
    <v-card shaped>
      <v-card-title class="secondary-inverted">
        <h2 v-if="$vuetify.theme.dark" style="color: black">Login</h2>
        <h2 v-else style="color: white">Login</h2>
        <v-spacer/>
        <v-btn v-if="$vuetify.theme.dark" icon color="black" @click="closeLoginCb">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-else icon color="white" @click="closeLoginCb">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="secondary-inverted pt-3">
        <h3 v-if="$vuetify.theme.dark" style="color: black">Welcome back</h3>
        <h3 v-else style="color: white">Welcome back</h3>
      </v-card-subtitle>

      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-if="this.loginProcessing"
      ></v-progress-linear>

      <v-card-text class=mt-5>
        <v-form ref="form_login" lazy-validation>
          <v-text-field
              ref="input_usernameOrEmail"
              v-model="loginForm.usernameOrEmail"
              prepend-icon="mdi-account"
              label="Username/Email"
              :rules="this.rules.required"
              required
              v-on:keyup.enter="goToPasswordInput"
          ></v-text-field>
          <v-text-field
              ref="input_password"
              v-model="loginForm.plaintextPassword"
              prepend-icon="mdi-lock"
              label="Password"
              required
              :rules="this.rules.required"
              type="password"
              v-on:keyup.enter="login"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-alert :value="loginAlert" color="error">Incorrect login credentials.</v-alert>

      <v-divider></v-divider>

      <v-card-actions>
        <a class="btn-shine" @click="switchToRegisterDialogCb">i'm new here</a>
        <v-spacer></v-spacer>
        <v-btn color="happy" class="btn-login" centered text @click="login">
          Login  <v-icon right color="happy">mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store"
import rules from "@/common/form-rules";

export default {
  store,
  props: {
    loginDialogOpen: {required: true},
    closeLoginCb: {required: true},
    switchToRegisterDialogCb: {required: true}
  },
  data() {
    return {
      rules,
      loginForm: {
        usernameOrEmail: "",
        plaintextPassword: ""
      },
      loginAlert: false,
      loginProcessing: false
    }
  },
  methods: {
    login() {
      if (!this.$refs.form_login.validate()) {
        return
      }

      this.loginAlert = false
      this.loginProcessing = true;
      store.dispatch("login", this.loginForm)
        .then(() => {
          this.loginForm.usernameOrEmail = "";
          this.loginForm.plaintextPassword = "";
          this.$refs.form_login.resetValidation();
          this.closeLoginCb();
        })
        .catch(() => this.loginAlert = true)
        .finally(() => this.loginProcessing = false);
    },
    goToPasswordInput() {
      this.$refs.input_password.focus();
    }
  }
};
</script>

<style scoped lang="scss">
.btn-login {
  margin-right: 10px;
}

.btn-shine {
  padding: 0 48px;
  margin: -28px; // negative margin to keep padding for animation
  background: linear-gradient(to right, gray 0, gold 10%, gray 20%) 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  font-style: italic;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

</style>