<template>
  <v-expansion-panel class="no-background">
    <v-expansion-panel-header>
      <InstrumentPreviewHeader :stock="stock" :quote="quote"></InstrumentPreviewHeader>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <InstrumentPreviewDetails :stock="stock"></InstrumentPreviewDetails>
      <InstrumentPreviewControls :stock="stock"></InstrumentPreviewControls>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import InstrumentPreviewHeader from "./InstrumentPreviewHeader";
  import InstrumentPreviewDetails from "./InstrumentPreviewDetails";
  import InstrumentPreviewControls from "./InstrumentPreviewControls";
  // import InstrumentService from "@/services/instrument.service"

  export default {
    components: {InstrumentPreviewHeader, InstrumentPreviewDetails, InstrumentPreviewControls},
    props: {
      stock: {required: true, type: Object}
    },
    data() {
      return {
        quote: []
      }
    },
    mounted() {
      // this.loadSymbolData(this.symbol);
      // this.getQuote(this.symbol);
    },
    methods: {
      // getQuote: async function(symbol) {
      //   const { data } = await InstrumentService.getQuote(symbol);
      //   if (data === undefined || data.error) {
      //     this.showErrorSnackbar(`Bad result from FH getQuote query for symbol ${symbol}, e => ${data}`, 3000);
      //     return;
      //   }
      //   this.quote = data;
    },
    computed: {
      // symbolData() {
      //
      // }
    }
  }
</script>

<style scoped>
  .v-expansion-panels .v-expansion-panel.no-background{
    background-color: transparent;
  }
</style>